<!--
 * @Description: 服务单详情
 * @Author: ChenXueLin
 * @Date: 2021-10-14 15:42:00
 * @LastEditTime: 2022-10-18 09:20:38
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'服务单状态:' + basicInfo.serviceStatus"
            :time="basicInfo.followDateStr"
          >
            <template v-slot:leftButtons>
              <div
                class="danger-status"
                v-for="(item, index) in tags"
                :key="index"
              >
                {{ item }}
              </div>
            </template>
            <template v-slot:rightButtons>
              <el-button
                type="primary"
                @click="myHandle(1)"
                v-if="
                  basicInfo.nowHandleByIsDept == 1 &&
                    (basicInfo.serviceStatusCode == 1 ||
                      basicInfo.serviceStatusCode == 2)
                "
                >我来处理
              </el-button>
              <el-button
                type="primary"
                @click="handleTurn"
                v-if="
                  basicInfo.nowHandleByIsDept != 1 &&
                    (basicInfo.serviceStatusCode == 1 ||
                      basicInfo.serviceStatusCode == 2) &&
                    basicInfo.hasRight == 1
                "
                >转单</el-button
              >
              <el-button
                type="primary"
                @click="openEditBaseInfoDailog"
                v-if="
                  (basicInfo.serviceStatusCode == 1 ||
                    basicInfo.serviceStatusCode == 2) &&
                    basicInfo.hasRight == 1
                "
                >修改基础信息</el-button
              >
              <el-button
                type="primary"
                @click="addService"
                v-if="
                  basicInfo.serviceStatusCode == 1 ||
                    basicInfo.serviceStatusCode == 2
                "
                >添加服务详情</el-button
              >
              <el-button
                type="primary"
                @click="handleSetTime"
                v-if="
                  basicInfo.serviceStatusCode == 1 ||
                    basicInfo.serviceStatusCode == 2
                "
                >设置跟进时间</el-button
              >
              <el-button
                type="primary"
                @click="closeTask('服务单')"
                v-if="
                  (basicInfo.serviceStatusCode == 1 ||
                    basicInfo.serviceStatusCode == 2) &&
                    basicInfo.hasRight == 1
                "
                >关闭服务单</el-button
              >
            </template>
          </detail-title-content>
          <div class="baseInfo-content-wrap">
            <div class="detailInfo-content">
              <el-tabs v-model="activeName">
                <el-tab-pane label="基本信息" name="one">
                  <!-- 维修服务单详情 -->
                  <base-info
                    :basicInfo="basicInfo"
                    @setLoading="setLoading"
                  ></base-info>
                </el-tab-pane>
                <el-tab-pane label="关联单据" name="two">
                  <tree-chart :json="jsonData" />
                </el-tab-pane>
                <el-tab-pane label="费用明细" name="three">
                  <fee-detail-table
                    :show="activeName == 'three'"
                    :taskId="serviceId"
                  ></fee-detail-table>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="record-box">
              <el-tabs v-model="recordName">
                <el-tab-pane label="服务详情" name="one">
                  <div class="record-item-box">
                    <record-item :recordsList="recordsList"></record-item>
                  </div>
                </el-tab-pane>
                <!-- <el-tab-pane label="历史记录" name="second">
                  <div class="record-item-box">
                    <record-item
                      v-for="item in recordsList"
                      :key="item"
                    ></record-item>
                  </div>
                </el-tab-pane> -->
              </el-tabs>
            </div>
          </div>

          <!-- 基本信息页面 -->
        </div>
      </section>
      <!-- 表格 end -->
    </div>
    <!-- 添加服务详情弹框 -->
    <add-service-detail
      :taskId="serviceId"
      :taskNum="basicInfo.serviceNo"
      :addServiceDialog="addServiceDialog"
      @handleClose="handleClose"
      :customerId="basicInfo.corpId"
      :contact="basicInfo.contactName"
      :customerName="basicInfo.corpName"
      @getTaskServiceReq="getTaskServiceReq"
      v-if="addServiceDialog"
    ></add-service-detail>
    <!-- 设置跟进时间 -->
    <set-time
      :setTimeDialog="setTimeDialog"
      @handleClose="handleClose"
      :taskId="serviceId"
      @getTaskBasicInfo="getServiceBasicInfoReq"
      v-if="setTimeDialog"
    ></set-time>
    <!-- 转单弹框 -->
    <el-dialog
      v-dialogDrag
      title="转单"
      :visible="turnOrderVisible"
      width="400px"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="closeTurnDialog"
      custom-class="turn-order-dialog"
      v-if="turnOrderVisible"
    >
      <el-form
        :model="turnForm"
        ref="turnForm"
        :rules="turnFormRules"
        lable-width="60"
        :inline="true"
      >
        <el-form-item label="接单客服" prop="handlerBy">
          <all-user-search
            v-model="turnForm.handlerBy"
            clear
            placeholder="请选择接单客服"
            title="请选择接单客服"
            :propsConfig="{
              id: 'employeeId',
              label: 'userName'
            }"
          >
          </all-user-search>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeTurnDialog">取消</el-button>
        <el-button type="primary" @click="confirmTurn">确定</el-button>
      </div>
    </el-dialog>

    <!-- 修改工单信息弹窗 -->
    <editBaseInfoDailog
      :taskId="serviceId"
      ref="editBaseInfoDailog"
      v-if="editBaseInfoDailogVisible"
      :serviceTypeCode="basicInfo.serviceTypeCode"
      @update="getServiceBasicInfoReq"
      :taskSource="basicInfo.taskSource"
    ></editBaseInfoDailog>
  </div>
</template>

<script>
import base from "@/mixins/base";
//设置跟进时间弹框
import setTime from "@/components/workOrderManage/setTime.vue";
//添加服务详情
import addServiceDetail from "@/components/workOrderManage/addServiceDetail.vue";
import detailTitleContent from "@/components/detailTitleContent";
import recordItem from "@/components/workOrderManage/recordItem.vue";
import editBaseInfoDailog from "@/views/WOS/workOrderManage/taskListManage/detail/components/editBaseInfoDailog";
import allUserSearch from "@/components/allUserSearch";
// 费用明细
import feeDetailTable from "@/components/workOrderManage/fee/feeDetailTable";
//关联单据
import TreeChart from "@/components/treeChart";
import BaseInfo from "./page/baseInfo.vue";
import { getServiceBasicInfo, getTaskService } from "@/api";
import { printError } from "@/utils/util";
import taskDetail from "@/mixins/taskDetail";
import eventBus from "@/bus";
export default {
  name: "serviceDetail",
  mixins: [base, taskDetail],
  components: {
    addServiceDetail,
    setTime,
    detailTitleContent,
    recordItem,
    feeDetailTable,
    TreeChart,
    BaseInfo,
    editBaseInfoDailog,
    allUserSearch
  },
  data() {
    return {
      loading: false,
      basicInfo: {},
      recordsList: [],
      tags: [],
      activeName: "one",
      collectDialog: false,
      splieOrderDialog: false,
      editBaseInfoDailogVisible: false,
      jsonData: [],
      recordName: "one",
      /*****转单*********/
      turnOrderVisible: false,
      turnForm: {
        handlerBy: ""
      },
      turnFormRules: {
        handlerBy: [
          {
            required: true,
            message: "请选择接单客服",
            trigger: ["blur", "change"]
          }
        ]
      },
      taskType: 2 //1为服务单
    };
  },
  computed: {
    btnType() {
      let value = "";
      if (this.basicInfo.serviceStatusCode == 1) {
        value = "yellowBtn";
      }
      if (this.basicInfo.serviceStatusCode == 2) {
        value = "blueBtn";
      }
      if (
        this.basicInfo.serviceStatusCode == 3 ||
        this.basicInfo.serviceStatusCode == 4
      ) {
        value = "greenBtn";
      }
      return value;
    }
  },
  watch: {
    activeName: {
      immediate: true,
      handler(val) {
        if (val == "one" && this.taskId) {
          this.getServiceBasicInfoReq();
          this.getAssociatedBills();
        }
      }
    }
  },
  created() {
    this.initForm();
    eventBus.$on("reload", id => {
      this.reload(id);
    });
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.serviceId = hasParamsRoute.params.serviceId;
      if (this.serviceId) {
        this.getServiceBasicInfoReq();
        this.getTaskServiceReq();
        this.getAssociatedBills();
      }
      //刷新
      eventBus.$on("refresh", () => {
        this.getServiceBasicInfoReq();
      });
    },
    // 打开基础信息修改弹窗
    openEditBaseInfoDailog() {
      this.editBaseInfoDailogVisible = true;
      this.$nextTick(() => {
        this.$refs.editBaseInfoDailog.init(this.basicInfo);
      });
    },
    //获取基本信息
    async getServiceBasicInfoReq() {
      try {
        this.loading = true;
        let res = await getServiceBasicInfo({
          serviceId: this.serviceId
        });
        //基本信息
        this.basicInfo = this.getFreezeResponse(res, {
          path: "data"
        });
        if (this.basicInfo.tags.length) {
          this.tags = this.basicInfo.tags.split(",");
        } else {
          this.tags = [];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取服务详情列表
    async getTaskServiceReq() {
      try {
        let res = await getTaskService({
          id: this.serviceId
        });
        this.recordsList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //创建服务单
    createTask() {
      this.routerPush({
        name: "workOrderManage/createTask",
        params: {
          refresh: true
        }
      });
    },
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    /*******转单************/
    //点击转单按钮
    handleTurn() {
      this.turnOrderVisible = true;
    },
    //确定转单
    confirmTurn() {
      this.$refs.turnForm.validate(valid => {
        if (valid) {
          this.myHandle(2);
        }
      });
    },
    //取消转单
    closeTurnDialog() {
      this.turnForm = {
        handlerBy: []
      };
      this.$refs.turnForm.resetFields();
      this.turnOrderVisible = false;
    },
    //点击单据跳转刷新
    reload(id) {
      this.activeName = "one";
      this.serviceId = id;
      this.getServiceBasicInfoReq();
      this.getTaskServiceReq();
      this.getAssociatedBills();
      //刷新
      eventBus.$on("refresh", () => {
        this.getServiceBasicInfoReq();
      });
    }
  },
  beforeDestroy() {
    eventBus.$off("refresh");
  }
};
</script>
<style lang="scss" scoped>
.main-wrapper > .container-wrapper .content-view-wrapper .content-view {
  padding: 0px;
}
.main-wrapper .edit-wrapper .edit-wrapper__body {
  height: 100%;
}
.baseInfo-content-wrap {
  display: flex;
  width: 100%;
  // height: 100%;
  position: relative;
  .detailInfo-content {
    width: calc(100% - 300px);
    height: 100%;
    border-right: 1px solid #f0f0f0;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .task-list-content {
    .table-title-btn {
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 50px;
      font-size: 14px;
      box-sizing: border-box;
      color: #303133;
      border-width: 1px 0;
      border-style: solid;
      border-color: #f5f7fa;
      background-color: #f9fafc;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      i {
        margin-left: 10px;
        height: 28px;
        margin-right: 10px;
        color: #46bfea;
        font-size: 12px;
        line-height: 28px;
        font-style: normal;
        background-color: #daf2fb; //
        text-align: center;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 3px;
      }
      [class^="e6-icon"] {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        padding: 0;
        font-size: 16px;
      }
    }
    .search-list {
      background: #fff !important;
    }
    .label-button-box {
      width: 100%;
      padding: 5px 10px;
      box-sizing: border-box;
    }
  }
  .record-box {
    width: 300px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }
}
/deep/.turn-order-dialog {
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
